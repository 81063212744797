/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useContext } from 'react'
import { Card, Nav, Tab, Button, OverlayTrigger, Popover, ButtonGroup } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RelevantProducts from './Top-Sales-Details/RelevantProducts'
import RelevantVideos from './Top-Sales-Details/RelevantVideos'
import RelevantLive from './Top-Sales-Details/RelevantLive'
import CalenderInfluencer from './Top-Sales-Details/CalenderInfluencer'
import Axios from '../../../plugins/axios'
import Conversion from '../../CommonComponents/Conversion'
import tikTokLogo from '../../../../images/tiktok-logo.png'
import { Context } from '../../../../MasterLayout';
import { useTranslation } from 'react-i18next';


function InfluencerTopSales() {
    const { t } = useTranslation();
    const { dispatch } = useContext(Context);
    const childRef = useRef();
    const childRelevantLiveRef = useRef();
    const calender = useRef();
    const params = useParams()
    const [details, setDetails] = useState(null);
    const [chineseNames, setCategorynames] = useState([]);
    const [favorite, setFavorite] = useState(false);
    const [, setCopied] = useState(false);

    const [followerRank, setfollowerrank] = useState('');
    const [followerRankdata, setfollowerRankdata] = useState();
    const [followerrankbutton, setfollowerrankbutton] = useState(true);
    const navigate = useNavigate()
    const [tab, setTab] = useState('');
    useEffect(() => {
        fetchdata();
    }, [])

    const fetchdata = async () => {
        try {
            const followerrank = await Axios.post('/api/influencer-author-followerrank', { author_id: params.influencerId })
            dispatch({ type: 'subscription_expired', response: followerrank.data.statusCode });

            const response = await Axios.post('/api/influencer-author-detail', { author_id: params?.influencerId });
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });
            if (response.data.data[0].is_commerce && !response.data.data[0].is_live) {
                navigate('/influencers/details/' + response.data.data[0].id + '/video-creators')
            }

            if (response.data && response.data.data && response.data.data[0] && ((response.data.data[0].is_commerce && response.data.data[0].is_commerce === true) || (response.data.data[0].is_live && response.data.data[0].is_live === true))) {
                setTab('relevantproducts');
            } else {
                setTab('relevantvideos');
            }
            const categories = response.data.data[0].category.split(',');
            const chineseNames = categories.map(cat => {
                const matchedCategory = global.config?.chineseCategories?.find(chCat => chCat.namecn === cat || chCat.name === cat);
                if (matchedCategory && matchedCategory.name) return matchedCategory.name;
            });
            setCategorynames(chineseNames)
            setfollowerRankdata(followerrank?.data?.data)
            setfollowerrank(followerrank?.data?.data?.author_country_rank);
            response?.data?.favorite?.length ? setFavorite(response?.data?.favorite?.some(fav => fav?.influencer_id === params?.influencerId)) : setFavorite(false);
            setDetails(response?.data?.data[0]);
        } catch (error) {
            console.error(error);
            setTab('relevantvideos');
        }
    }

    const countryicon = Object.keys(global?.config?.region).find(key => global?.config?.region[key] === details?.sys_country?.country)

    const handleCopy = () => {
        let contact = []
        if (details?.unique_id) {
            contact.push(`TikTok:${details?.unique_id}`)
        }
        if (details?.email) {
            contact.push(`Email:${details?.email}`)
        }
        if (details?.instagram) {
            contact.push(`Instagram:${details?.instagram}`)
        }
        if (details?.twitter_name) {
            contact.push(`Twitter:${details?.twitter_name}`)
        }
        if (details?.zalo) {
            contact.push(`Zalo:${details?.zalo}`)
        }

        if (details?.youtube_channel_title) {
            contact.push(`YouTube:${details?.youtube_channel_title}`)
        }
        const contactdata = contact.join(';');

        navigator.clipboard.writeText(contactdata)
            .then(() => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 2000);
            })
            .catch((error) => console.error('Error copying text: ', error));
        toast.success("Contact Successfully copied!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const addFavorite = async () => {
        try {
            const response = await Axios.post('/api/favorite-influencer', { influencer_id: details?.id })
            setFavorite(response.data.favorite)
        } catch (error) {
            console.error(error)
        }

    }
    const handleClick = () => {
        const url = `https://www.tiktok.com/@${details?.unique_id}`;
        window.open(url, '_blank');
    }

    const copyContactInfo = (value) => {
        navigator.clipboard.writeText(value).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        }).catch((error) => console.error('Error copying text: ', error));
        toast.success("Successfully copied!", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    return (
        <>
            {details &&
                <>
                    <Card>
                        <Card.Body>
                            <div className='row'>
                                <div className='col-lg-1'>
                                    <Link to={`https://www.tiktok.com/@${details.unique_id}`} target='_blank' rel="noreferrer" >
                                        <img alt="" width={70} className="prod-thum-img" src={`https://t-img.picturehaven.net/tikmeta/${details?.avatar_larger_privatization}?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                    </Link>
                                </div>
                                <div className='col-lg-11'>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <Link to={`https://www.tiktok.com/@${details.unique_id}`} target='_blank' rel="noreferrer" >
                                                <h4 className="mb-0 mr-5">{details?.nickname}</h4>
                                            </Link>
                                            <Button onClick={addFavorite} variant="outline-dark" size="xs" className="px-2 py-1 mr-1"><i
                                                className={"fa " + (favorite ? "fa-star text-primary" : "fa-star-o")}
                                            ></i></Button>
                                        </div>
                                        <div className="text-right">
                                            <p className="mb-0"><small>{t('influencers.longtext_1')}</small></p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-5'>
                                            <div className='mb-3'>
                                                <a href={`https://www.tiktok.com/@${details.unique_id}`} target='_blank' rel="noreferrer" >
                                                    <p className='mb-0 text-primary'>
                                                        @{details?.unique_id}
                                                    </p>
                                                </a>
                                                {details?.email || details?.instagram || details?.twitter_name || details?.zalo || details?.youtube_channel_title ?
                                                    <div style={{ border: "2px solid yellow", width: "11rem" }}>{t('influencers.available_for_contact')}</div>
                                                    : ''}
                                                <div className='d-flex'>
                                                    <p className='mb-0 mr-4'><strong>{t('influencers.country_or_region')}:</strong> <span className={`fi fi-${countryicon?.toLocaleLowerCase()} mr-1`}></span> {details?.sys_country?.country}</p>
                                                    <p className='mb-0'><strong>{t('influencers.lang')}:</strong> {details?.language_code || '--'}</p>
                                                </div>
                                                <div className="prd-title-dtl">
                                                    <p className='d-flex align-items-center mt-2 mb-2'><strong className='mr-2'>{t('influencers.type')}:</strong>
                                                        {details?.category && chineseNames?.length != 0 && <div className="d-flex">
                                                            <span className="btn btn-outline-dark btn-xs px-2 py-1 mr-1">{chineseNames[0]}</span> <br />
                                                            {chineseNames[1] && <><span className="btn btn-outline-dark btn-xs px-2 py-1 mr-1">{chineseNames[1]}</span> <br /></>}
                                                            {chineseNames?.length > 2 && <OverlayTrigger
                                                                key="chineseNames"
                                                                trigger="click"
                                                                placement="bottom"
                                                                rootClose
                                                                overlay={
                                                                    <Popover className="variants-tooltip">
                                                                        <Popover.Body>
                                                                            <div className="m-2">
                                                                                {chineseNames && chineseNames.slice(2).map((v, i) => (
                                                                                    <span key={i} className="btn btn-outline-dark btn-xs px-2 py-1 mr-1">{v}</span>
                                                                                ))}
                                                                            </div>
                                                                        </Popover.Body>
                                                                    </Popover>
                                                                }
                                                            >
                                                                <Button variant="outline-dark" size="xs" className='px-2 py-1'>{chineseNames.length > 1 ? ('+' + (chineseNames.length - 2)) : ''}  <i className="fa fa-chevron-down" aria-hidden="true"></i></Button>
                                                            </OverlayTrigger>}
                                                        </div>}
                                                    </p>
                                                </div>
                                                <p className='mb-0'><strong>{t('influencers.introduction')}:</strong>
                                                    &nbsp; {details?.signature ? `${details?.signature.length > 60 ? details?.signature.substring(0, 60) + '...' : details?.signature}` : '--'}
                                                </p>

                                                <p className='mb-0'><strong className='mr-2'>{t('common.contact_information')}:</strong>
                                                    {/* <button style={{ border: 'none', backgroundColor: 'white' }} onClick={handleCopy}> */}
                                                    <button
                                                        onClick={handleCopy}
                                                        type="button"
                                                        className="btn btn-link btn-sm mr-2"
                                                        id="toastr-success-bottom-left"
                                                    >
                                                        <ToastContainer
                                                            position="bottom-left"
                                                            autoClose={5000}
                                                            hideProgressBar={false}
                                                            newestOnTop
                                                            closeOnClick
                                                            rtl={false}
                                                            pauseOnFocusLoss
                                                            draggable
                                                            pauseOnHover
                                                        />
                                                        <i className="fa fa-copy"></i>{t('influencers.click_to_copy_all_contacts')}
                                                    </button>
                                                </p>
                                                <p>
                                                    <OverlayTrigger
                                                        key="tikTok"
                                                        trigger={["hover", "focus"]}
                                                        placement="top"
                                                        rootClose
                                                        overlay={<Popover className="variants-tooltip">
                                                            <Popover.Body>
                                                                <strong>{t('influencers.tikTok')}:</strong> {details?.unique_id}
                                                            </Popover.Body>
                                                        </Popover>
                                                        }
                                                    >
                                                        <Button onClick={handleClick} variant="outline-dark" className='mr-2' size="xs">
                                                            <img alt="" width={10} src={tikTokLogo} />
                                                        </Button>
                                                    </OverlayTrigger>

                                                    {details?.email && <OverlayTrigger
                                                        key="email"
                                                        trigger={["hover", "focus"]}
                                                        placement="top"
                                                        rootClose
                                                        overlay={<Popover className="variants-tooltip">
                                                            <Popover.Body>
                                                                <strong>{t('influencers.email')}:</strong> {details?.email}
                                                            </Popover.Body>
                                                        </Popover>
                                                        }
                                                    >
                                                        <Button variant="outline-dark" size="xs" className='mr-2' onClick={() => copyContactInfo(details?.email)}>
                                                            <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                                        </Button>
                                                    </OverlayTrigger>}

                                                    {details?.instagram && <OverlayTrigger
                                                        key="instagram"
                                                        trigger={["hover", "focus"]}
                                                        placement="top"
                                                        rootClose
                                                        overlay={<Popover className="variants-tooltip">
                                                            <Popover.Body>
                                                                <strong>{t('influencers.instagram')}:</strong> {details?.instagram}
                                                            </Popover.Body>
                                                        </Popover>
                                                        }
                                                    >
                                                        <Button variant="outline-dark" size="xs" className='mr-2' onClick={() => copyContactInfo(details?.instagram)}>
                                                            <i className="fa fa-instagram" aria-hidden="true"></i>
                                                        </Button>
                                                    </OverlayTrigger>}

                                                    {details?.twitter_name && <OverlayTrigger
                                                        key="twitter_name"
                                                        trigger={["hover", "focus"]}
                                                        placement="top"
                                                        rootClose
                                                        overlay={<Popover className="variants-tooltip">
                                                            <Popover.Body>
                                                                <strong>{t('influencers.twitter')}:</strong> {details?.twitter_name}
                                                            </Popover.Body>
                                                        </Popover>
                                                        }
                                                    >
                                                        <Button variant="outline-dark" size="xs" className='mr-2' onClick={() => copyContactInfo(details?.twitter_name)}>
                                                            <i className="fa fa-twitter" aria-hidden="true"></i>
                                                        </Button>
                                                    </OverlayTrigger>}

                                                    {details?.zalo && <OverlayTrigger
                                                        key="zalo"
                                                        trigger={["hover", "focus"]}
                                                        placement="top"
                                                        rootClose
                                                        overlay={<Popover className="variants-tooltip">
                                                            <Popover.Body>
                                                                <strong>{t('influencers.zalo')}:</strong> {details?.zalo}
                                                            </Popover.Body>
                                                        </Popover>
                                                        }
                                                    >
                                                        <Button variant="outline-dark" size="xs" className='mr-2' onClick={() => copyContactInfo(details?.zalo)}>
                                                            <i className="fa fa-external-link" aria-hidden="true"></i>
                                                        </Button>
                                                    </OverlayTrigger>}

                                                    {details?.youtube_channel_title && <OverlayTrigger
                                                        key="youtube_channel_title"
                                                        trigger={["hover", "focus"]}
                                                        placement="top"
                                                        rootClose
                                                        overlay={<Popover className="variants-tooltip">
                                                            <Popover.Body>
                                                                <strong>{t('influencers.youtube')}:</strong> {details?.youtube_channel_title}
                                                            </Popover.Body>
                                                        </Popover>
                                                        }
                                                    >
                                                        <Button variant="outline-dark" size="xs" className='mr-2' onClick={() => copyContactInfo(details?.youtube_channel_title)}>
                                                            <i className="fa fa-youtube" aria-hidden="true"></i>
                                                        </Button>
                                                    </OverlayTrigger>}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-lg-7'>
                                            <div className='row mt-4'>
                                                <div className='col-lg-4 mb-3'>
                                                    <p className='mb-0'>{t('influencers.followers_rank')}</p>
                                                    <h4 className='mb-2'>NO: &nbsp;{followerRank ? <Conversion value={followerRank} /> || '--' : '--'}</h4>
                                                    <ButtonGroup size="sm">
                                                        <Button variant={!followerrankbutton ? "primary" : "outline-primary"} className='py-1 px-2' onClick={() => {
                                                            setfollowerrank(followerRankdata.author_global_rank)
                                                            setfollowerrankbutton(false);
                                                        }}>
                                                            {t('influencers.global')}
                                                        </Button>
                                                        <Button variant={followerrankbutton ? "primary" : "outline-primary"}
                                                            onClick={() => {
                                                                setfollowerrank(followerRankdata.author_country_rank)
                                                                setfollowerrankbutton(true);
                                                            }}
                                                            className='py-1 px-2'>
                                                            {t('influencers.region')}
                                                        </Button>
                                                    </ButtonGroup>
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <p className='mb-0'>{t('common.followers')}</p>
                                                    <h4 className='mb-0'>{details?.follower_count ? <Conversion value={details?.follower_count} /> : '--'}</h4>
                                                    <p className='mb-0'>{t('influencers.Vs_yesterday_0')}</p>
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <p className='mb-0'>{t('influencers.revenue_of_last_30_Days')}</p>
                                                    <h4 className='mb-0'>{details?.thirty_days_sales_amount_by_usd || '--'}</h4>
                                                    <p className='mb-0'>{details?.thirty_days_sales_amount ? `(${details?.thirty_days_sales_amount})` : '--'}</p>
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <p className='mb-0'>{t('common.videos')}</p>
                                                    <h4 className='mb-0'>{<Conversion value={details?.video_count} /> || '--'}</h4>
                                                    <p className='mb-0'>{t('influencers.Vs_yesterday_0')}</p>
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <p className='mb-0'>{t('common.views')}</p>
                                                    <h4 className='mb-0'>{details?.play_count ? <Conversion value={details?.play_count} /> : '0'}</h4>
                                                    <p className='mb-0'>{t('influencers.Vs_yesterday')}
                                                        <span
                                                            className={
                                                                details?.increase_play_count > 0 ? 'text-success' :
                                                                    details?.increase_play_count < 0 ? 'text-danger' :
                                                                        'text-muted'
                                                            }
                                                        > &nbsp;{details?.increase_play_count && (details?.increase_play_count > 0 ? "+" : "")}  {details?.increase_play_count ? <Conversion value={details?.increase_play_count} /> : '0'}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <p className='mb-0'>{t('common.likes')}</p>
                                                    <h4 className='mb-0'>{details?.digg_count ? <Conversion value={details?.digg_count} /> : '--'}</h4>
                                                    <p className='mb-0'>{t('influencers.Vs_yesterday_0')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>

                    <div className="custom-tab-1">
                        <Tab.Container
                            defaultActiveKey={tab}
                        >
                            <Nav as="ul" className="nav-tabs mb-0 bg-white">
                                {((details.is_live && details.is_live == true) || (details.is_commerce && details.is_commerce == true)) && <Nav.Item as="li" key="relevantproducts">
                                    <Nav.Link eventKey="relevantproducts" as={Link}
                                    >
                                        <i className="fa fa-bar-chart" aria-hidden="true"></i> {t('influencers.relevant_products')}
                                    </Nav.Link>
                                </Nav.Item>}
                                <Nav.Item as="li" key="relevantvideos">
                                    <Nav.Link eventKey="relevantvideos" as={Link} onClick={() => childRef.current.relevantVideos()}
                                    >
                                        <i className="fa fa-file-video-o" aria-hidden="true"></i> {t('influencers.relevant_videos')}
                                    </Nav.Link>
                                </Nav.Item>

                                {
                                    details.is_live && details.is_live == true && params.name != "video-creators" &&
                                    <Nav.Item as="li" key="relevantlive">
                                        <Nav.Link eventKey="relevantlive" as={Link} onClick={() => childRelevantLiveRef.current.relevantLive()}
                                        >
                                            <i className="fa fa-video-camera" aria-hidden="true"></i> {t('influencers.relevant_live')}
                                        </Nav.Link>
                                    </Nav.Item>}

                                <Nav.Item as="li" key="calender">
                                    <Nav.Link eventKey="calender" as={Link} onClick={() => calender.current.calender()}>
                                        <i className="fa fa-user" aria-hidden="true"></i> {t('influencers.calender')}
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane
                                    eventKey="relevantproducts"
                                    key="relevantproducts"
                                >
                                    <RelevantProducts />
                                </Tab.Pane>

                                <Tab.Pane
                                    eventKey="relevantvideos"
                                    key="relevantvideos"
                                >
                                    <RelevantVideos ref={childRef} />
                                </Tab.Pane>


                                <Tab.Pane
                                    eventKey="relevantlive"
                                    key="relevantlive"
                                >
                                    <RelevantLive ref={childRelevantLiveRef} />
                                </Tab.Pane>

                                <Tab.Pane
                                    eventKey="calender"
                                    key="calender"
                                >
                                    <CalenderInfluencer ref={calender} />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </>
            }
        </>
    )
}

export default InfluencerTopSales
import React, { useEffect, useRef, useState, useContext } from 'react'
import { Card, Nav, Tab, Button, Popover, ButtonGroup, OverlayTrigger } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import RelevantVideosInfSearch from './Influencer-Search-Details/RelevantVideosInfSearch';
import CalenderInfluencer from './Top-Sales-Details/CalenderInfluencer';
import Axios from '../../../plugins/axios';
import tikTokLogo from '../../../../images/tiktok-logo.png';
import Conversion from '../../CommonComponents/Conversion';
import { ToastContainer, toast } from 'react-toastify';
import { Context } from '../../../../MasterLayout';
import { useTranslation } from 'react-i18next';


function InfluencerSearchDetails() {
    const { t } = useTranslation();
    const childReff = useRef();
    const calender = useRef();

    const params = useParams();

    const { dispatch } = useContext(Context);
    const [details, setDetails] = useState();
    const [chineseNames, setCategorynames] = useState([]);
    const [favorite, setFavorite] = useState(false);
    const [, setCopied] = useState(false);
    const [followerRank, setfollowerrank] = useState('');
    const [followerRankdata, setfollowerRankdata] = useState();
    const [followerrankbutton, setfollowerrankbutton] = useState(true);
    const navigate = useNavigate()

    async function fetchData() {
        try {
            const followerrank = await Axios.post('/api/influencer-author-followerrank', { author_id: params.influencerId })
            dispatch({ type: 'subscription_expired', response: followerrank.data.statusCode });
            const response = await Axios.post('/api/influencer-author-detail', { author_id: params.influencerId })
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });
            if (!response?.data?.data[0]?.is_commerce && response?.data?.data[0]?.is_live) {
                return navigate('/influencers/details/' + response?.data?.data[0].id + '/breakout')
            }
            const categories = response.data.data[0].category.split(',');
            const chineseNames = categories.map(cat => {
                const matchedCategory = global.config?.chineseCategories?.find(chCat => chCat.namecn === cat || chCat.name === cat);
                return matchedCategory.name;
            });
            setCategorynames(chineseNames)

            response?.data?.favorite?.length ? setFavorite(response?.data?.favorite?.some(fav => fav?.influencer_id === params.influencerId)) : setFavorite(false);
            setfollowerRankdata(followerrank?.data?.data)
            setfollowerrank(followerrank?.data?.data?.author_country_rank);
            setDetails(response?.data?.data[0]);
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        fetchData();
    }, [])
    const InfluencerSearchCountries = { ...global.config.region, ...global.config.countries };
    const countryicon = Object.keys(InfluencerSearchCountries).find(key => InfluencerSearchCountries[key] === details?.sys_country?.country)

    const handleCopy = () => {
        let contact = []
        if (details?.unique_id) {
            contact.push(`TikTok:${details?.unique_id}`)
        }
        if (details?.email) {
            contact.push(`Email:${details?.email}`)
        }
        if (details?.instagram) {
            contact.push(`Instagram:${details?.instagram}`)
        }
        if (details?.twitter_name) {
            contact.push(`Twitter:${details?.twitter_name}`)
        }
        if (details?.zalo) {
            contact.push(`Zalo:${details?.zalo}`)
        }

        if (details?.youtube_channel_title) {
            contact.push(`YouTube:${details?.youtube_channel_title}`)
        }
        const contactdata = contact.join(';');

        navigator.clipboard.writeText(contactdata)
            .then(() => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 2000);
            })
            .catch((error) => console.error('Error copying text: ', error));
        toast.success("Contact Successfully copied!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const addFavorite = async () => {
        try {
            const response = await Axios.post('/api/favorite-influencer', { influencer_id: details?.id })
            setFavorite(response.data.favorite)
        } catch (error) {
            console.error(error)
        }

    }

    const handleClick = () => {
        const url = `https://www.tiktok.com/@${details?.unique_id}`;
        window.open(url, '_blank');
    }

    const copyContactInfo = (value) => {
        navigator.clipboard.writeText(value).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        }).catch((error) => console.error('Error copying text: ', error));
        toast.success("Successfully copied!", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }


    return (
        <>{details &&
            <>
                <Card>
                    <Card.Body>
                        <div className='row'>
                            <div className='col-lg-1'>
                                <Link to={`https://www.tiktok.com/@${details.unique_id}`} target='_blank' rel="noreferrer" >
                                    <img alt="" width={70} className="prod-thum-img" src={`https://t-img.picturehaven.net/tikmeta/${details?.avatar_larger_privatization}?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                                </Link>
                            </div>
                            <div className='col-lg-11'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <Link to={`https://www.tiktok.com/@${details.unique_id}`} target='_blank' rel="noreferrer" >
                                            <h4 className="mb-0 mr-5">{details?.nickname}</h4>
                                        </Link>
                                        <Button onClick={addFavorite} variant="outline-dark" size="xs" className="px-2 py-1 mr-1"><i
                                            className={"fa " + (favorite ? "fa-star text-primary" : "fa-star-o")}
                                        ></i></Button>
                                    </div>
                                    <div className="text-right">
                                        <p className="mb-0"><small>{t('influencers.longtext_1')}</small></p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-5'>
                                        <div className='mb-3'>
                                            <a href={`https://www.tiktok.com/@${details.unique_id}`} target='_blank' rel="noreferrer" >
                                                <p className='mb-0 text-primary'>
                                                    @{details?.unique_id}
                                                </p>
                                            </a>
                                            {details?.is_commerce && <div className='d-flex'>
                                                <p className='mb-0 mr-4 text-white bg-warning p-1'><i className="fa fa-address-book" aria-hidden="true"></i> Available for contact</p>
                                            </div>
                                            }
                                            <div className='d-flex'>
                                                <p className='mb-0 mr-4'><strong>{t('influencers.country_or_region')}:</strong> <span className={`fi fi-${countryicon?.toLocaleLowerCase()} mr-1`}></span> {details?.sys_country?.country}</p>
                                                <p className='mb-0'><strong>{t('influencers.lang')}:</strong> {details?.language_code}</p>
                                            </div>
                                            <div className="prd-title-dtl">
                                                <p className='d-flex align-items-center mt-2 mb-2'><strong className='mt-1'>{t('influencers.type')}:</strong>
                                                    {details?.category && chineseNames?.length != 0 && <div className="d-flex">
                                                        <span className="btn btn-outline-dark btn-xs px-2 py-1 mr-1 mb-1">{chineseNames[0]}</span> <br />
                                                        {chineseNames[1] && <><span className="btn btn-outline-dark btn-xs px-2 py-1 mr-1 mb-1">{chineseNames[1]}</span> <br /></>}
                                                        {chineseNames?.length > 2 && <OverlayTrigger
                                                            key="chineseNames"
                                                            trigger="click"
                                                            placement="bottom"
                                                            rootClose
                                                            overlay={
                                                                <Popover className="variants-tooltip">
                                                                    <Popover.Body>
                                                                        <div className="m-2">
                                                                            {chineseNames && chineseNames.slice(2).map((v, i) => (
                                                                                <span key={i} className="btn btn-outline-dark btn-xs px-2 py-1 mr-1">{v}</span>
                                                                            ))}

                                                                        </div>
                                                                    </Popover.Body>
                                                                </Popover>
                                                            }
                                                        >
                                                            <Button variant="outline-dark" size="xs" className='px-2 py-1'>{chineseNames.length > 1 ? ('+' + (chineseNames.length - 2)) : ''}  <i className="fa fa-chevron-down" aria-hidden="true"></i></Button>
                                                        </OverlayTrigger>}
                                                    </div>}
                                                </p>
                                            </div>
                                            <p className='mb-0'><strong>{t('influencers.introduction')}:</strong>
                                                <span>{details?.signature.length > 40 ? details?.signature.substring(0, 40) + '...' : details?.signature}</span>
                                            </p>
                                            <p className='mb-0'><strong className='mr-2'>{t('common.contact_information')}:</strong>
                                                <button
                                                    type="button"
                                                    className="btn btn-link btn-sm mr-2"
                                                    id="toastr-success-bottom-left"
                                                    onClick={handleCopy}
                                                >
                                                    <ToastContainer
                                                        position="bottom-left"
                                                        autoClose={5000}
                                                        hideProgressBar={false}
                                                        newestOnTop
                                                        closeOnClick
                                                        rtl={false}
                                                        pauseOnFocusLoss
                                                        draggable
                                                        pauseOnHover
                                                    />
                                                    <i className="fa fa-copy"></i>{t('influencers.click_to_copy_all_contacts')}

                                                </button>
                                            </p>
                                            <p>


                                                <OverlayTrigger
                                                    key="tikTok"
                                                    trigger={["hover", "focus"]}
                                                    placement="top"
                                                    rootClose
                                                    overlay={<Popover className="variants-tooltip">
                                                        <Popover.Body>
                                                            <strong>{t('influencers.tikTok')}:</strong> {details?.unique_id}
                                                        </Popover.Body>
                                                    </Popover>
                                                    }
                                                >
                                                    <Button onClick={handleClick} variant="outline-dark" className='mr-2' size="xs">
                                                        <img alt="" width={10} src={tikTokLogo} />
                                                    </Button>
                                                </OverlayTrigger>

                                                {details?.email && <OverlayTrigger
                                                    key="email"
                                                    trigger={["hover", "focus"]}
                                                    placement="top"
                                                    rootClose
                                                    overlay={<Popover className="variants-tooltip">
                                                        <Popover.Body>
                                                            <strong>{t('influencers.email')}:</strong> {details?.email}
                                                        </Popover.Body>
                                                    </Popover>
                                                    }
                                                >
                                                    <Button variant="outline-dark" size="xs" className='mr-2' onClick={() => copyContactInfo(details?.email)}>
                                                        <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                                    </Button>
                                                </OverlayTrigger>}

                                                {details?.instagram && <OverlayTrigger
                                                    key="instagram"
                                                    trigger={["hover", "focus"]}
                                                    placement="top"
                                                    rootClose
                                                    overlay={<Popover className="variants-tooltip">
                                                        <Popover.Body>
                                                            <strong>I{t('influencers.instagram')}:</strong> {details?.instagram}
                                                        </Popover.Body>
                                                    </Popover>
                                                    }
                                                >
                                                    <Button variant="outline-dark" size="xs" className='mr-2' onClick={() => copyContactInfo(details?.instagram)}>
                                                        <i className="fa fa-instagram" aria-hidden="true"></i>
                                                    </Button>
                                                </OverlayTrigger>}

                                                {details?.twitter_name && <OverlayTrigger
                                                    key="twitter_name"
                                                    trigger={["hover", "focus"]}
                                                    placement="top"
                                                    rootClose
                                                    overlay={<Popover className="variants-tooltip">
                                                        <Popover.Body>
                                                            <strong>{t('influencers.twitter')}:</strong> {details?.twitter_name}
                                                        </Popover.Body>
                                                    </Popover>
                                                    }
                                                >
                                                    <Button variant="outline-dark" size="xs" className='mr-2' onClick={() => copyContactInfo(details?.twitter_name)}>
                                                        <i className="fa-brands fa-square-twitter" aria-hidden="true"></i>
                                                    </Button>
                                                </OverlayTrigger>}

                                                {details?.zalo && <OverlayTrigger
                                                    key="zalo"
                                                    trigger={["hover", "focus"]}
                                                    placement="top"
                                                    rootClose
                                                    overlay={<Popover className="variants-tooltip">
                                                        <Popover.Body>
                                                            <strong>{t('influencers.zalo')}:</strong> {details?.zalo}
                                                        </Popover.Body>
                                                    </Popover>
                                                    }
                                                >
                                                    <Button variant="outline-dark" size="xs" className='mr-2' onClick={() => copyContactInfo(details?.zalo)}>
                                                        <i className="fa fa-external-link" aria-hidden="true"></i>
                                                    </Button>
                                                </OverlayTrigger>}

                                                {details?.youtube_channel_title && <OverlayTrigger
                                                    key="youtube_channel_title"
                                                    trigger={["hover", "focus"]}
                                                    placement="top"
                                                    rootClose
                                                    overlay={<Popover className="variants-tooltip">
                                                        <Popover.Body>
                                                            <strong>{t('influencers.youtube')}:</strong> {details?.youtube_channel_title}
                                                        </Popover.Body>
                                                    </Popover>
                                                    }
                                                >
                                                    <Button variant="outline-dark" size="xs" className='mr-2' onClick={() => copyContactInfo(details?.youtube_channel_title)}>
                                                        <i className="fa fa-youtube" aria-hidden="true"></i>
                                                    </Button>
                                                </OverlayTrigger>}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-lg-7'>
                                        <div className='row mt-4'>
                                            <div className='col-lg-4 mb-3'>
                                                <p className='mb-0'>{t('influencers.followers_rank')}</p>
                                                <h4 className='mb-2'>NO: &nbsp;{followerRank ? <Conversion value={followerRank} /> || '--' : '--'}</h4>

                                                <ButtonGroup size="sm">
                                                    <Button variant={!followerrankbutton ? "primary" : "outline-primary"} className='py-1 px-2' onClick={() => {
                                                        setfollowerrank(followerRankdata?.author_global_rank)
                                                        setfollowerrankbutton(false);
                                                    }}> {t('influencers.global')}</Button>
                                                    <Button variant={followerrankbutton ? "primary" : "outline-primary"}
                                                        onClick={() => {
                                                            setfollowerrank(followerRankdata?.author_country_rank)
                                                            setfollowerrankbutton(true);
                                                        }}
                                                        className='py-1 px-2'>{t('influencers.region')}</Button>
                                                </ButtonGroup>
                                            </div>
                                            <div className='col-lg-4 mb-3'>
                                                <p className='mb-0'>{t('common.followers')}</p>
                                                <h4 className='mb-0'>{details?.follower_count ? <Conversion value={details?.follower_count} /> : '--'}</h4>
                                                <p className='mb-0'>{t('influencers.Vs_yesterday_0')}</p>
                                            </div>
                                            <div className='col-lg-4 mb-3'>
                                                <p className='mb-0'>{t('common.revenue30days')}</p>
                                                <h4 className='mb-0'>{details?.thirty_days_sales_amount_by_usd || '--'}</h4>
                                                <p className='mb-0'>({details?.thirty_days_sales_amount || '--'})</p>
                                            </div>
                                            <div className='col-lg-4 mb-3'>
                                                <p className='mb-0'>{t('common.videos')}</p>
                                                <h4 className='mb-0'>{details?.video_count ? <Conversion value={details?.video_count} /> : '--'}</h4>
                                                <p className='mb-0'>{t('influencers.Vs_yesterday_0')}</p>
                                            </div>
                                            <div className='col-lg-4 mb-3'>
                                                <p className='mb-0'>{t('common.views')}</p>
                                                <h4 className='mb-0'>{details?.play_count ? <Conversion value={details?.play_count} /> : '--'}</h4>
                                                <p className='mb-0'>{t('influencers.Vs_yesterday')}
                                                    <span
                                                        className={
                                                            details?.increase_play_count > 0 ? 'text-success' :
                                                                details?.increase_play_count < 0 ? 'text-danger' :
                                                                    'text-muted'
                                                        }
                                                    >{details?.increase_play_count > 0 ? "+" : ""} <Conversion value={details?.increase_play_count} /></span>
                                                </p>
                                            </div>
                                            <div className='col-lg-4 mb-3'>
                                                <p className='mb-0'>{t('common.likes')}</p>
                                                <h4 className='mb-0'>{details?.digg_count ? <Conversion value={details?.digg_count} /> : '--'}</h4>
                                                <p className='mb-0'>{t('influencers.Vs_yesterday_0')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Card.Body>
                </Card>

                <div className="custom-tab-1">
                    <Tab.Container
                        defaultActiveKey="relevantvideos"
                    >
                        <Nav as="ul" className="nav-tabs mb-0 bg-white">
                            <Nav.Item as="li" key="relevantvideos">
                                <Nav.Link eventKey="relevantvideos" as={Link}>
                                    <i className="fa fa-file-video-o" aria-hidden="true"></i> {t('influencers.relevant_videos')}
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item as="li" key="calender">
                                <Nav.Link eventKey="calender" as={Link} onClick={() => calender.current.calender()}>
                                    <i className="fa fa-user" aria-hidden="true"></i> {t('influencers.calender')}
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane
                                eventKey="relevantvideos"
                                key="relevantvideos"
                            >
                                <RelevantVideosInfSearch
                                    ref={childReff}
                                />
                            </Tab.Pane>
                            <Tab.Pane
                                eventKey="calender"
                                key="calender"
                            >
                                <CalenderInfluencer ref={calender} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </>
        }
        </>
    )
}

export default InfluencerSearchDetails
import React, { useState, useEffect, useContext } from "react";
import { Card, Table, Button, Tooltip } from "react-bootstrap";
import Axios from "../../plugins/axios.js";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Category from "./Category";
import RangeSlider from "./RangeSlider";
import InfiniteScrollWrapper from "./InfiniteScrollWrapper";
import '../../../config';
import { Link } from "react-router-dom";
import { Context } from '../../../MasterLayout.js';
import { useTranslation } from 'react-i18next';
import Export from "../CommonComponents/export.js";


const ProductsSearch = () => {
   const { t } = useTranslation();
   const { state, dispatch } = useContext(Context);
   const [page, setPage] = useState(0);
   const [items, setItems] = useState([]);
   const [hasMore, setHasMore] = useState(true);
   const [countryCode, setCountryCode] = useState('');
   const [category1, setCategory1] = useState('');
   const [category2, setCategory2] = useState('');
   const [category3, setCategory3] = useState('');
   const [categoryId1, setCategoryId1] = useState('');
   const [categoryId2, setCategoryId2] = useState('');
   const [categoryId3, setCategoryId3] = useState('');
   const [shopKeyWord, setShopKeyWord] = useState('');
   const [shopAttribute, setShopAttribute] = useState('');
   const [freeShipping, setFreeShipping] = useState('');
   const [productStatus] = useState('');
   const [ratingRange, setRatingRange] = useState([]);
   const [keyword, setKeyword] = useState(state && state.keyword ? state.keyword : '');
   const [searchClick, setSearchClick] = useState(0);
   const [favourite, setFavourite] = useState([]);
   const [payload, setPayload] = useState({});
   const [sorting, setSorting] = useState({ sort: 14, sort_type: 'DESC' });
   const sortType = sorting.sort_type == 'DESC' ? 'ASC' : 'DESC';
   const sortClass = sorting.sort_type == 'DESC' ? 'sorting-desc' : 'sorting-asc';

   const ratingRangeOption = {
      '0-1': '0-1',
      '1-2': '1-2',
      '2-3': '2-3',
      '3-4': '3-4',
      '4-5': '4-5',
   };

   useEffect(() => {
      //do operation on state change
      setItems([]);
      setPage(0);
      productSearch(0);
   }, [category1, category2, category3, countryCode, shopKeyWord, shopAttribute, freeShipping, productStatus, searchClick, ratingRange, sorting])

   const markFavourite = async (data) => {
      await Axios.post('/api/favorite-product', data);
      const index = favourite.indexOf(data.id);
      if (index > -1) {
         favourite.splice(index, 1);
      } else {
         favourite.push(data.id);
      }

      setFavourite(favourite.concat([]));
   };

   const selectCountry = async (countryCode) => {
      setCountryCode(countryCode);
   };

   const handleCategory = async (categoryIds, categoryNames) => {
      setCategory1(categoryNames[0] ? categoryNames[0] : '');
      setCategory2(categoryNames[1] ? categoryNames[1] : '');
      setCategory3(categoryNames[2] ? categoryNames[2] : '');
      setCategoryId1(categoryIds[0] ? categoryIds[0] : '');
      setCategoryId2(categoryIds[1] ? categoryIds[1] : '');
      setCategoryId3(categoryIds[2] ? categoryIds[2] : '');
   };

   const handleRatingRange = async (value) => {
      setRatingRange(value);
   };

   const productSearch = async (page) => {
      try {

         let params = {
            page,
            sort: sorting.sort,
            sort_type: sorting.sort_type,
         };
         if (countryCode != '') {
            params['country_code'] = countryCode;
         }
         if (category1 != '') {
            params['category1'] = category1;
         }
         if (category2 != '') {
            params['category2'] = category2;
         }
         if (category3 != '') {
            params['category3'] = category3;
         }
         if (categoryId1 != '') {
            params['category_id1'] = categoryId1;
         }
         if (categoryId2 != '') {
            params['category_id2'] = categoryId2;
         }
         if (categoryId3 != '') {
            params['category_id3'] = categoryId3;
         }
         if (shopKeyWord != '') {
            params['shop_key_word'] = shopKeyWord;
         }
         if (shopAttribute != '') {
            params['shop_key_word'] = shopAttribute;
         }
         if (freeShipping != '') {
            params['free_shipping'] = freeShipping == 1 ? true : false;
         }
         if (productStatus != '') {
            params['product_status'] = productStatus;
         }
         if (keyword != '') {
            params['keyword'] = keyword;
            dispatch({ type: 'keyword_searched', response: '' });
         }
         if (ratingRange.from && ratingRange.to) {
            params['start_product_rating'] = ratingRange.from;
            params['end_product_rating'] = ratingRange.to;
         }
         setPayload(params)
         let response = await Axios.post('/api/product-search', params).catch(e => console.error('Axios-Error: ' + e.message));
         dispatch({ type: 'subscription_expired', response: response.data.statusCode });

         if (response && response.status === 200 && response.data.data) {
            let array = await response.data.favorite ? response.data.favorite.map((value, index) => value.product_id) : favourite;
            if (response && response.status === 200) {
               if (page == 0) {
                  setItems(response.data.data);
                  setFavourite(array);
               } else {
                  setItems(items.concat(response.data.data));
                  setFavourite(favourite.concat(array));
               }
               if (response.data.data.length === 0 || response.data.data.length < 20) {
                  setHasMore(false);
               } else {
                  setHasMore(true);
               }
            } else {
               setHasMore(false);
            }
         }
      } catch (error) {
         console.error('Error: ' + error.message);
      };
   }

   const handleSearch = async () => {
      setItems([]);
      const clickCount = parseInt(searchClick) + 1;
      setSearchClick(clickCount);
   };

   const functionNext = async () => {
      let number = page + 1;
      setPage(number);
      productSearch(number)
   }

   const regionList = Object.keys(global.config.region).map(function (key) {
      return <a key={key} className={"btn my-1 " + (countryCode == key ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 d-flex align-items-center mb-2"} onClick={() => { selectCountry(key); }}><span className={`fi fi-${key.toLocaleLowerCase()} mr-1`}></span>  {global.config.region[key]}</a>
   });

   const handleSort = async (sort, sortType) => {
      if (sort != sorting.sort || (sortType && sortType != sorting.sort_type)) {
         setSorting({ sort, sort_type: sortType });
      };
   };

   return (
      <>
         <Card>
            <Card.Body className='p-3'>
               <div className='topfilter'>
                  <div className="d-flex align-items-center mb-3 justify-content-end">
                     <div className="input-group mb-3 w-auto">
                        <input type="text" className="form-control form-control-sm" placeholder={t("product_research.products_search.search_for_products")} value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                        <div className="input-group-append">
                           <button className="btn btn-primary btn-xs" type="button" onClick={() => handleSearch()}>
                              {t('product_research.products_search.search')}
                           </button>
                        </div>
                     </div>
                  </div>
                  <div className="d-flex align-items-center flex-wrap mb-2">
                     <p className="m-0 mr-3"><strong>{t('product_research.products_search.country')}</strong></p>
                     <a className={"btn " + (countryCode == '' ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 mb-2 my-1"}
                        onClick={() => selectCountry('')}>
                        {t('product_research.products_search.all')}
                     </a>
                     {regionList}
                  </div>
                  <Category singleCategory={false} handleCategory={handleCategory} categoryId1={categoryId1} endLevelShow={1} />
                  <div className="d-flex align-items-center flex-wrap mb-3">
                     <p className="m-0 mr-3"><strong>{t('product_research.products_search.filters')}</strong></p>
                     <div className="form-group d-flex align-items-center mb-0">
                        <label className="form-label text-nowrap mb-0 mr-3">
                           {t('product_research.products_search.tikTok_shop_name')}
                        </label>
                        <input type="text" className="form-control form-control-sm" placeholder={t('product_research.products_search.long_text_1')} onBlur={(e) => { setShopKeyWord(e.target.value); }} />
                     </div>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <p className="m-0 mr-3"><strong>{t('product_research.products_search.advanced_filtering')}</strong></p>
                     <div className="form-group mb-0 mr-3">
                        <select className="form-control form-control-sm" onChange={(e) => { setShopAttribute(e.target.value); }}>
                           <option value="">{t('product_research.products_search.shop_attribute')}</option>
                           <option value="overseas">{t('product_research.products_search.Cross_border_shop')}</option>
                           <option value="local">{t('product_research.products_search.local_shop')}</option>
                        </select>
                     </div>
                     <div className="form-group mb-0 mr-3">
                        <select className="form-control form-control-sm" onChange={(e) => { setFreeShipping(e.target.value); }}>
                           <option value="">{t('product_research.products_search.free_shipping')}</option>
                           <option value="1">{t('product_research.products_search.yes')}</option>
                           <option value="0">{t('product_research.products_search.no')}</option>
                        </select>
                     </div>
                     <div className="dropdown product-rating-menu">
                        <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle"
                           role="button"
                           id="dropdownMenuLink"
                           data-bs-toggle="dropdown" aria-expanded="false">
                           {t('product_research.products_search.product_rating')}
                        </a>
                        <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuLink">
                           <RangeSlider min={0} max={5} defaultMin={0} defaultMax={5} rangeOption={ratingRangeOption} rangeOptionName={'rating'} step={0.1} handleUpdate={handleRatingRange} />
                        </div>
                     </div>
                  </div>
                  <div className="d-flex justify-content-end align-items-center mx-2 my-3">
                  <Export payload={payload} data_source="Products Search" type="Product Search"/>
                  </div>
               </div>
            </Card.Body>
         </Card>
         <Card>
            <Card.Body className='p-2'>
               <Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                     <InfiniteScrollWrapper
                        isInfiniteScrollOn={true}
                        lengthData={items.length}
                        functionNext={functionNext}
                        hasMore={hasMore}
                     >
                        <table id="example" className="display w-100 dataTable">
                           <thead>
                              <tr role="row">
                                 <th className='text-right'>#</th>
                                 <th>{t('product_research.products_search.products')}</th>
                                 <th>{t('product_research.products_search.price')}</th>
                                 <th>{t('product_research.products_search.shops')}</th>
                                 <th className="text-right">{t('product_research.products_search.commission')}</th>
                                 <th className={`text-right sorting ${14 == sorting.sort ? sortClass : ''}`} onClick={() => { handleSort(14, sorting.sort == 14 ? sortType : 'ASC') }}>{t('product_research.products_search.sales')}</th>
                                 <th className={`text-right sorting ${35 == sorting.sort ? sortClass : ''}`} onClick={() => { handleSort(35, sorting.sort == 35 ? sortType : 'ASC') }}>{t('product_research.products_search.revenue')}</th>
                                 <th className="text-right">{t('product_research.products_search.relevant')}<br /> {t('product_research.products_search.videos')}</th>
                                 <th className="text-right">{t('product_research.products_search.relevant')}<br /> {t('product_research.products_search.influencers')}</th>
                                 <th className="text-right">{t('product_research.products_search.favourite')}</th>
                              </tr>
                           </thead>
                           <tbody>
                              {items.map((product, index) => (
                                 <tr key={index}>
                                    <td className='text-right'>{index + 1}</td>
                                    <td>
                                       <div className="d-flex">
                                          <div className="prd-img mr-3">
                                             <OverlayTrigger
                                                key="right"
                                                trigger={["hover", "focus"]}
                                                placement="right"
                                                rootClose
                                                overlay={
                                                   <Popover>
                                                      <Popover.Body>
                                                         <img src={"https://t-img.picturehaven.net/product/" + product.images_privatization[0] + "?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={500} />
                                                      </Popover.Body>
                                                   </Popover>
                                                }
                                             >
                                                <Button variant="link" className='p-0' size="xs">
                                                   <Link to={'/products/' + product.id + '/details'} target="_blank">
                                                      <img src={"https://t-img.picturehaven.net/product/" + product.images_privatization[0] + "?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />
                                                   </Link>
                                                </Button>
                                             </OverlayTrigger>
                                          </div>
                                          <div className="prd-title">
                                             <p className="">
                                                <OverlayTrigger overlay={<Tooltip>{product.title}</Tooltip>}>
                                                   <Link to={'/products/' + product.id + '/details'} target="_blank">
                                                      <span>{product.title.length > 70 ? product.title.substring(0, 70) + '...' : product.title}</span>
                                                   </Link>
                                                </OverlayTrigger>
                                             </p>
                                             <span className={`fi fi-${product?.country_code.toLocaleLowerCase()} mr-1`}></span>
                                          </div>
                                       </div>
                                    </td>
                                    <td>
                                       {product.sale_props ?
                                          <div className="d-flex justify-content-between">
                                             <div className="mr-3">
                                                {product.price_usd}<br />
                                                <span className="text-muted">({product.price})</span>
                                             </div>
                                             <div className='d-flex p-variants'>
                                                <div className='v-btn'>
                                                   <OverlayTrigger
                                                      key="right"
                                                      trigger="click"
                                                      placement="right"
                                                      rootClose
                                                      overlay={
                                                         <Popover id={`popover-positioned-$"right"`} className="variants-tooltip">
                                                            <Popover.Header as="h3">Variants</Popover.Header>
                                                            <Popover.Body>
                                                               <div className="d-flex mb-3">
                                                                  <div className="mr-3">
                                                                     <img src={"https://t-img.picturehaven.net/product/" + product.images_privatization[0] + "?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={200} />
                                                                  </div>
                                                                  <div>
                                                                     <p className="">{product.title}</p>
                                                                  </div>
                                                                  <div>
                                                                     {product.price_usd}<br />
                                                                     <span className="text-muted">({product.price})</span>
                                                                  </div>
                                                               </div>
                                                               {product.sale_props.map((variant, index1) => (
                                                                  <div className="mb-3" key={index1}>
                                                                     <p className="mb-0"><strong>{variant.prop_name}</strong></p>
                                                                     <div className="variant-box">
                                                                        {variant.sale_prop_values.map((value, index2) => (
                                                                           <Button key={index2} variant="outline-dark" size="xs" className="mb-1 mr-1">{value.prop_value}</Button>
                                                                        ))}
                                                                     </div>
                                                                  </div>
                                                               ))}
                                                            </Popover.Body>
                                                         </Popover>
                                                      }
                                                   >
                                                      <Button variant="outline-primary" size="xs">Variants</Button>
                                                   </OverlayTrigger>
                                                </div>
                                                <div className='v-truck'>
                                                   {product.free_shipping &&
                                                      <OverlayTrigger overlay={<Tooltip>Free Shipping</Tooltip>}>
                                                         <span><i className="fa fa-truck mt-2 ml-2 text-muted" aria-hidden="true"></i></span>
                                                      </OverlayTrigger>
                                                   }
                                                </div>
                                             </div>
                                          </div>
                                          : ''}
                                    </td>
                                    <td>
                                       {!product.shop ? <span>Closed</span> :
                                          <div className="d-flex align-items-center">
                                             <OverlayTrigger
                                                key="right"
                                                trigger={["hover", "focus"]}
                                                placement="right"
                                                rootClose
                                                overlay={
                                                   <Popover>
                                                      <Popover.Body>
                                                         {product.shop.shop_logo_privatization && <img src={"https://t-img.picturehaven.net/product/" + product.shop.shop_logo_privatization} alt="" width={500} />}
                                                      </Popover.Body>
                                                   </Popover>
                                                }
                                             >
                                                <Button variant="link" className='p-0' size="xs">
                                                   <Link to={'/shops/' + product.shop.shop_id + '/details'} target="_blank">
                                                      {product.shop.shop_logo_privatization && <img src={"https://t-img.picturehaven.net/product/" + product.shop.shop_logo_privatization} alt="" width={80} />}
                                                   </Link>
                                                </Button>
                                             </OverlayTrigger>
                                             <div className="ml-2">
                                                <Link to={'/shops/' + product.shop.shop_id + '/details'} target="_blank">
                                                   <p>{product.shop.shop_name}</p>
                                                </Link>
                                             </div>
                                          </div>}
                                    </td>
                                    <td className="text-right">
                                       {product.commission_rate ? product.commission_rate : '-'}
                                    </td>
                                    <td className="text-right">
                                       {product.sold_count_str ? product.sold_count_str : '-'}
                                    </td>
                                    <td className="text-right">
                                       {product.total_sales_usd}<br />
                                       <span className="text-muted">({product.total_sales})</span>
                                    </td>
                                    <td className="text-right">
                                       {product.related_videos ? product.related_videos : '-'}
                                    </td>
                                    <td className="text-right">
                                       {product.related_author ? product.related_author : '-'}
                                    </td>
                                    <td className="text-right">
                                       <div className="d-flex justify-content-end">
                                          { /*<div className="mr-1">
                                             <Button variant="outline-dark" size="xs" className="px-2 py-1"><i className="fa fa-line-chart" aria-hidden="true"></i></Button>
                                          </div>*/ }
                                          <div>
                                             {<Button
                                                variant="outline-dark" size="xs"
                                                className="px-2 py-1"
                                                onClick={() => { markFavourite({ id: product.id, country_code: product.country_code }); }}>
                                                <i className={"fa " + (favourite.indexOf(product.id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                                             </Button>}
                                          </div>
                                       </div>
                                    </td>
                                 </tr>
                              ))}
                           </tbody>
                        </table>
                     </InfiniteScrollWrapper>
                  </div>
               </Table>
            </Card.Body>
         </Card>
      </>
   );
};

export default ProductsSearch;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useContext } from 'react'
import { Card, Button, ButtonGroup } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import "react-toastify/dist/ReactToastify.css";
import Axios from '../../../plugins/axios'
import Conversion from '../../CommonComponents/Conversion'
import { Context } from '../../../../MasterLayout';
import { useTranslation } from 'react-i18next';
import HashtageAnalysisChart from './HashtageAnalysisChart';
import HashtageHotVideos from './HashtageHotVideos';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';


function HashtageDetail() {
    const { t } = useTranslation();
    const dateFilterOption = {
        '3_days': {
            label: 'Past 3 Days',
            start_day: moment().subtract(3, 'days').format('YYYY-MM-DD'),
            end_day: moment().subtract(1, 'days').format('YYYY-MM-DD')
        }
    };
    const { dispatch } = useContext(Context);
    const customerReviewsRef = useRef();
    const params = useParams()
    const [details, setDetails] = useState();
    const dateRangeRef = useRef();
    const [page, setPage] = useState(0);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [sort, setSort] = useState(3)
    const [favorite, setFavorite] = useState(false);
    const [hashtageVideoFilter, setHashtageVideoFilter] = useState('3_days');
    const [dateRange, setDateRange] = useState(dateFilterOption[hashtageVideoFilter]);
    const filterArray = [
        {
            title: 'Views',
            sort: 3
        },
        {
            title: 'Likes',
            sort: 8
        },
        {
            title: 'Comments',
            sort: 5
        }
    ]

    useEffect(() => {
        fetchdata();
        videoSearch(0);
    }, [sort, dateRange]);
    const functionNext = async () => {
        let number = page + 1;
        setPage(number);
        videoSearch(number)
    }

    const videoSearch = async (page) => {
        try {
            let data = {
                tag_id: params?.tagId,
                page: page,
                sort: sort,
            }
            if (hashtageVideoFilter === 'custom') {
                data['start_day'] = dateRange.start_day
                data['end_day'] = dateRange.end_day
            } else {
                data['start_day'] = dateRange.start_day
                data['end_day'] = dateRange.end_day
            }

            let response = await Axios.post('/api/tag/topVideo', data);

            dispatch({ type: 'subscription_expired', response: response.data.statusCode });
            if (response && response.status === 200 && response.data.data) {
                if (page == 0) {
                    setItems(response.data.data);
                } else {
                    setItems(items.concat(response.data.data));
                }
                if (response.data.data.length === 0 || response.data.data.length < 20) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                }
            }
        } catch (error) {
            console.error('Error: ' + error.message);
        }
    }

    const fetchdata = async () => {
        try {
            const response = await Axios.post('/api/tag/detail', { tag_id: params?.tagId });
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });
            setFavorite(response?.data?.data?.favorite)
            setDetails(response?.data?.data);
        } catch (error) {
            console.error(error);
        }
    }


    const addFavorite = async () => {
        try {
            const response = await Axios.post('/api/hashtage/favourite', { id: details?.id })
            setFavorite(response.data.favorite)
        } catch (error) {
            console.error(error)
        }

    }
    const handleApply = (event, picker) => {
        picker.element.val(`${picker.startDate.format('YYYY-MM-DD')} - ${picker.endDate.format('YYYY-MM-DD')}`);
        setDateRange({ start_day: picker.startDate.format('YYYY-MM-DD'), end_day: picker.endDate.format('YYYY-MM-DD') });
        setHashtageVideoFilter('custom');
    };

    const handleCancel = (event, picker) => {
        picker.element.val('');
        setDateRange([]);
    };



    return (
        <>
            {details &&
                <>
                    <Card>
                        <Card.Body>
                            <div className='row'>
                                <div className='col-lg-2 d-flex justify-content-center align-items-center'>
                                    <div className="d-flex align-item-center">
                                        <div className="prd-img mr-3">
                                            {details.cover_large_privatization && <img src={"https://t-img.picturehaven.net/tikmeta/" + details.cover_large_privatization + "?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-10'>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <h4 className="mb-0 mr-5">{details?.title}</h4>
                                            <Button onClick={addFavorite} variant="outline-dark" size="xs" className="px-2 py-1 mr-1"><i
                                                className={"fa " + (favorite ? "fa-star text-primary" : "fa-star-o")}
                                            ></i></Button>
                                        </div>
                                    </div>
                                    <div className='row'>

                                        <div className='col-lg-5'>
                                            <div className='row mt-4'>


                                                <div className='col-lg-4 mb-3'>
                                                    <p className='mb-0'>{t('creator_center.hashtage_ranking.hashtage_detail.hot_play_country')}: {details?.top_country_code ? details?.top_country_code : ''}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-7'>
                                            <div className='row mb-3'>
                                                <div className='col-lg-4'>
                                                    <p className='mb-0'>{t('creator_center.hashtage_ranking.hashtage_detail.views')}</p>
                                                    <h4 className='mb-0'>{details?.play_count ? <Conversion value={details?.play_count} /> : '--'}</h4>
                                                    <p className='mb-0'>{t('creator_center.hashtage_ranking.hashtage_detail.yesterday')} - </p>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <p className='mb-0'>{t('creator_center.hashtage_ranking.hashtage_detail.likes')}</p>
                                                    <h4 className='mb-0'>{details?.digg_count ? <Conversion value={details?.digg_count} /> : '0'}</h4>
                                                    <p className='mb-0'>{t('creator_center.hashtage_ranking.hashtage_detail.yesterday')} - </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <HashtageAnalysisChart ref={customerReviewsRef} />
                    <div className="col-lg-5">
                        <h3>{t('creator_center.hashtage_ranking.title.hot_videos')}</h3>
                    </div>
                    <Card>
                        <Card.Body>
                            <div>
                                <div className="d-flex justify-content-end align-items-center m-2">
                                    <div>
                                        <ButtonGroup>
                                            {filterArray.length > 0 &&
                                                filterArray.map(val => (
                                                    <Button key={val?.title} variant="outline-dark" size="xs" className={val?.sort === sort ? 'btn-primary' : ''} onClick={() => setSort(val?.sort)}>{val?.title}</Button>
                                                ))
                                            }
                                        </ButtonGroup>

                                    </div>
                                    <div className='mx-4'>
                                        <DateRangePicker ref={dateRangeRef} initialSettings={{ startDate: moment(new Date(dateRange.start_day)).format('MM/DD/YYYY'), endDate: moment(new Date(dateRange.end_day)).format('MM/DD/YYYY') }} onApply={handleApply} onCancel={handleCancel}>
                                            <input type="text" placeholder={'First Seen'} className="form-control form-control-sm input-daterange-timepicker w-auto" value={`${dateRange.start_day} - ${dateRange.end_day}`} />
                                        </DateRangePicker>
                                    </div>

                                </div>
                            </div>
                            <HashtageHotVideos functionNext={functionNext} items={items} hasMore={hasMore} />
                        </Card.Body>
                    </Card>


                </>
            }
        </>
    )
}

export default HashtageDetail;
import Axios from "../../plugins/axios";
import React, { useState, useEffect, useContext } from 'react';
import { Table, Button, Card, Form } from "react-bootstrap";
import '../../../css/storeListing/storeListing.css';
import { useTranslation } from 'react-i18next';
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import InfluancerVideoModal from "./CommonComponent/InfluencerVideoModal";
import InfluencerVideoDeleteModal from "./CommonComponent/InfluencerVideoDeleteModal";
import { Context } from "../../../MasterLayout";

const InfluancerVideoModule = () => {
    const sort = "createdAt";
    const limit = 10;
    const { t } = useTranslation();
    const { dispatch } = useContext(Context);
    const [modalShow, setModalShow] = useState(false);
    const [itemToUpdate, setItemToUpdate] = useState();
    const [update, setUpdate] = useState(false);
    const [page, setPage] = useState(1);
    const [items, setItems] = useState([]);
    const [sorting, setSorting] = useState({ sort: sort, sort_type: -1 });
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [itemIdToDelete, setItemIdToDelete] = useState(null);
    const [recordsCount, setRecordsCount] = useState(0);
    const [reinit, setReinit] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [search, setSearch] = useState('');

    const tableHead = [
        { title: t('Influencer_video.ranking'), className: 'text-left' },
        { title: t('Influencer_video.avatar'), className: 'text-left' },
        { title: t('Influencer_video.name'), className: 'text-left sorting', sort: 'name' },
        { title: `${t('Influencer_video.price')}`, className: 'text-left sorting', sort: 'price' },
        { title: t('Influencer_video.tiktok_url'), className: 'text-left' },
        { title: t('Influencer_video.action'), className: 'text-left' },
    ];

    useEffect(() => {
        getInfluencerVideo(1);
    }, [sorting]);

    const getInfluencerVideo = async (page) => {
        try {
            let params = {
                page: page,
                limit: limit,
                sort: sorting.sort,
                sort_type: sorting.sort_type,
                search
            };
            let queryString = '';
            for (let key in params) {
                if (params.hasOwnProperty(key)) {
                    if (queryString.length > 0) {
                        queryString += '&';
                    }
                    queryString += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
                }
            }
            const response = await Axios.get(`/api/influencer-videos?${queryString}`);
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });
            if (response && response.status === 200 && response.data.data) {
                const responseData = response.data;
                setItems(responseData.data.results);
                setRecordsCount(responseData.data.recordsCount)
                setPageCount(responseData.data.totalPages)
                setPage(responseData.data.currentPage)
            }
        } catch (error) {

        }
    }
    const handleSort = (sort, sortType) => {
        if (sort !== sorting.sort || (sortType && sortType !== sorting.sort_type)) {
            setSorting({ sort, sort_type: sortType });
        }
    };
    const handlePageClick = (event) => {
        setPage(event.selected + 1);
        setReinit(reinit + 1);
        getInfluencerVideo(event.selected + 1);
    };

    const handleEdit = (item) => {
        setUpdate(true);
        setItemToUpdate(item)
        setModalShow(!modalShow);
    };
    const handleDelete = (id) => {
        setItemIdToDelete(id);
        setShowDeleteModal(true);

    };
    const getSortClass = (sort) => {
        if (sort === sorting.sort) {
            return sorting.sort_type === -1 ? 'sorting-desc' : 'sorting-asc';
        }
        return '';
    };
    const handleEnterKeyPress = async (e) => {
        if (e.key === 'Enter') {
            setSearch(e.target.value);
            getInfluencerVideo(1);
        }
    };



    return (
        <>
            <div>
                <ToastContainer />
            </div>
            <Card>
                <Card.Body>
                    <div className="d-flex justify-content-end align-items-center">
                        <div className="d-flex align-items-center justify-content-center">
                            <p className="text-right my-0 mx-2">
                                <button type="button" className="btn btn-primary btn-xs" onClick={() => { setUpdate(false); setModalShow(!modalShow); setItemToUpdate(); }}>{t('common.add_influencer')}</button>
                            </p>
                        </div>
                        <div className="">
                            <Form.Control
                                value={search}
                                onBlur={() => { setReinit(reinit + 1); getInfluencerVideo(1); }}
                                onChange={(e) => setSearch(e.target.value)}
                                className="w-auto form-control-sm"
                                placeholder={t('common.search')}
                                onKeyPress={handleEnterKeyPress}
                            />
                        </div>

                    </div>
                    <Table id="example" className="display w-100 dataTable">
                        <thead>
                            <tr role="row">
                                {tableHead.map((v, i) => (
                                    <th key={i} className={(v.className || '') + ' ' + getSortClass(v.sort)} onClick={() => { v.sort && handleSort(v.sort, sorting.sort === v.sort ? -sorting.sort_type : 1) }}>
                                        {v.title}
                                        {v.sort && sorting.sort === v.sort && (
                                            <span className={`sorting-icon ${sorting.sort_type === -1 ? 'desc' : 'asc'}`} />
                                        )}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {items.length > 0 && items.map((item, index) =>
                                <tr key={index}>
                                    <td className='text-left'>{index + 1}</td>
                                    <td>
                                        <div className="d-flex">
                                            <div className="prd-img mr-3">
                                                {item.image !== '' && <img src={item.image} alt="" width={"20px"} className="" />}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <p>
                                            {item.name ? item.name : '--'}
                                        </p>
                                    </td>
                                    <td>
                                        <p>
                                            {item.price_list ? item.price_list[0].price : '--'}
                                        </p>
                                    </td>
                                    <td>
                                        {item.tiktok_url && <Link to={item.tiktok_url} target="_blank">
                                            <Button className="btn-sm" style={{ padding: '5px 10px', fontSize: '12px' }}>Visit Link</Button>
                                        </Link>}
                                    </td>
                                    <td>
                                        <Button variant="outline-dark" onClick={() => handleEdit(item)} size="xs" className="mr-1 px-2 py-1">
                                            <i className="fa fa-pencil"></i>
                                        </Button>
                                        <Button onClick={() => handleDelete(item._id)} variant="outline-danger" size="xs" className="mr-1 px-2 py-1">
                                            <i className="fa fa-trash"></i>
                                        </Button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <div className="dataTables_info">
                            {recordsCount == 0 ? "No - Record Found" :
                                `Showing ${(((page - 1) * limit) + 1)} to ${Math.min(page * limit, recordsCount)} of ${recordsCount} entries`}
                        </div>
                        <div className="dataTables_paginate paging_simple_numbers">
                            <ReactPaginate
                                nextLabel="Next >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={1}
                                pageCount={pageCount}
                                forcePage={page - 1}
                                previousLabel="< Previous"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <InfluancerVideoModal
                getInfluencerVideo={getInfluencerVideo}
                update={update}
                modalShow={modalShow}
                setModalShow={setModalShow}
                itemToUpdate={itemToUpdate}
                setItemToUpdate={setItemToUpdate}
            />
            <InfluencerVideoDeleteModal
                showDeleteModal={showDeleteModal}
                setShowDeleteModal={setShowDeleteModal}
                itemIdToDelete={itemIdToDelete}
                setItemIdToDelete={setItemIdToDelete}
                getInfluencerVideo={getInfluencerVideo}
            />
        </>
    );
};

export default InfluancerVideoModule;
import React from 'react';
import { Card } from "react-bootstrap";
import { useTranslation } from 'react-i18next';


const PlanUpgrade = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Card>
      {data && <Card.Body className="p-0">
        <div className="p-4">
          <p className="mb-0">{t('admin_dashboard.start')}: {data.start_date}</p>
          <p className="mb-0">{t('admin_dashboard.end')}: {data.end_date}</p>
          <hr className="mt-0" />
          <div className="row">
            <div className="mb-3">
              <h4 className="text-uppercase">{data.upgrades + data.not_upgrades} {t('admin_dashboard.total')}</h4>
            </div>
            <div className="col-6">
              <div className="mb-3">
                <h4 className="text-uppercase">{data.upgrades} {t('admin_dashboard.upgraded')}</h4>
              </div>
              <hr className="mt-0" />
              <div className="mb-3">
                <h4 className="text-uppercase">{data.canceled} {t('admin_dashboard.canceled')}</h4>
              </div>
              <hr className="mt-0" />
              <div className="mb-3">
                <h4 className="text-uppercase">{data.failed} {t('admin_dashboard.failed')}</h4>
              </div>
              <hr className="mt-0" />
              <div className="mb-3">
                <h4 className="text-uppercase">{data.upgrade_pending} {t('admin_dashboard.upgrade_pending')}</h4>
              </div>
            </div>
            <div className="col-6">
              <div className="mb-3">
                <h4 className="text-uppercase">{data.upgrades_percent}</h4>
              </div>
              <hr className="mt-0" />
              <div className="mb-3">
                <h4 className="text-uppercase">{data.canceled_percent}</h4>
              </div>
              <hr className="mt-0" />
              <div className="mb-3">
                <h4 className="text-uppercase">{data.failed_percent}</h4>
              </div>
              <hr className="mt-0" />
              <div className="mb-3">
                <h4 className="text-uppercase">{data.upgrade_pending_percent}</h4>
              </div>
            </div>
            <br /><hr /><br />
            <div className="mb-3">
              <h4 className="text-uppercase">{data.upgrades} {t('admin_dashboard.total_upgrades')}</h4>
            </div>
            <div className="col-6">
              <div className="mb-3">
                <h4 className="text-uppercase">{data.to_annual} {t('admin_dashboard.standard_annual')}</h4>
              </div>
              <hr className="mt-0" />
              <div className="mb-3">
                <h4 className="text-uppercase">{data.to_monthly} {t('admin_dashboard.standard_monthly')}</h4>
              </div>
            </div>
            <div className="col-6">
              <div className="mb-3">
                <h4 className="text-uppercase">{data.to_annual_percent}</h4>
              </div>
              <hr className="mt-0" />
              <div className="mb-3">
                <h4 className="text-uppercase">{data.to_monthly_percent}</h4>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>}
    </Card>
  );
};

export default PlanUpgrade;
import { React, useEffect, useState, useContext } from 'react';
import { ButtonGroup, Button, Card, Table, OverlayTrigger, Tooltip, Popover, Modal } from "react-bootstrap";
import RangeSlider from "./RangeSlider";
import Slider from "react-slick";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import InfiniteScrollWrapper from "./InfiniteScrollWrapper";
import Category from "./Category";
import Axios from "../../plugins/axios";
import "../../../config";
import Conversion from "../CommonComponents/Conversion";
import { TikTokEmbed } from 'react-social-media-embed';
import { Link } from 'react-router-dom';
import { Context } from '../../../MasterLayout';
import { useTranslation } from 'react-i18next';
import Export from '../CommonComponents/export';


const HotVideo = ({ tab, defaultCycle }) => {
   const { t } = useTranslation();

   const { dispatch } = useContext(Context);
   const [page, setPage] = useState(0);
   const [items, setItems] = useState([]);
   const [hasMore, setHasMore] = useState(true);
   const [countryCode, setCountryCode] = useState('US');
   const [category1, setCategory1] = useState('');
   const [category2, setCategory2] = useState('');
   const [categoryId1, setCategoryId1] = useState('');
   const [categoryId2, setCategoryId2] = useState('');
   const [salesRange, setSalesRange] = useState([]);
   const [revenueRange, setRevenueRange] = useState([]);
   const [cycle, setCycle] = useState(defaultCycle);
   const [version, setVersion] = useState('');
   const [versionList, setVersionList] = useState([]);
   const [dateRange, setDateRange] = useState([]);
   const [favourite, setFavourite] = useState([]);
   const [contentModal, setContentModal] = useState(false);
   const [tikTokEmbedID, setTikTokEmbedID] = useState('');
   const [payload, setPayload] = useState({});
   const [sorting, setSorting] = useState({ sort: 19, sort_type: 'DESC' });
   const sortType = sorting.sort_type == 'DESC' ? 'ASC' : 'DESC';
   const sortClass = sorting.sort_type == 'DESC' ? 'sorting-desc' : 'sorting-asc';

   const cycleList = {
      'H24': '1 Day',
      'D3': '3 Day',
      'W1': 'Weekly',
   };

   const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1
   };

   useEffect(() => {
      setItems([]);
      setPage(0);
      if (version == '') {
         getVersion(cycle);
      } else {
         productVideos(0);
      }
   }, [countryCode, category1, category2, salesRange, revenueRange, cycle, version, dateRange, sorting]);

   const getVersion = async (cycle) => {
      let params = {
         cycle
      }
      let response = await Axios.get('/api/version', { params });
      if (response.data.data) {
         setVersion(response.data.data[0].version);
      }
      setVersionList(response.data.data ? response.data.data : []);
   };

   const selectCountry = async (countryCode) => {
      setCountryCode(countryCode);
   };

   const handleCategory = async (categoryIds, categoryNames) => {
      setCategory1(categoryNames[0] ? categoryNames[0] : '');
      setCategory2(categoryNames[1] ? categoryNames[1] : '');
      setCategoryId1(categoryIds[0] ? categoryIds[0] : '');
      setCategoryId2(categoryIds[1] ? categoryIds[1] : '');
   };

   const regionList = Object.keys(global.config.region).map(function (key) {
      return <a key={key} className={"btn my-1 " + (countryCode == key ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 d-flex align-items-center mb-2"} onClick={() => { selectCountry(key); }}><span className={`fi fi-${key.toLocaleLowerCase()} mr-1`}></span> {global.config.region[key]}</a>
   });

   const cycleHtml = Object.keys(cycleList).map(function (key) {
      return <Button key={key} variant={"outline-primary " + (cycle == key ? 'btn-primary' : '')} size="xs" onClick={() => { setCycle(key); getVersion(key); }}>{cycleList[key]}</Button>
   });

   const productVideos = async (page) => {
      try {
         let params = {
            page,
            cycle,
            version,
            country_code: countryCode,
            sort: sorting.sort,
            sort_type: sorting.sort_type,
         };
         if (category1 != '') {
            params['category1'] = category1;
         };
         if (categoryId1 != '') {
            params['category_id1'] = categoryId1;
         };
         if (category2 != '') {
            params['category2'] = category2;
         };
         if (categoryId2 != '') {
            params['category_id2'] = categoryId2;
         };
         if (salesRange.from) {
            params['sold_count_start'] = salesRange.from;
         };
         if (salesRange.to && salesRange.to != '') {
            params['sold_count_end'] = salesRange.to;
         };
         if (revenueRange.from) {
            params['sales_amount_start'] = revenueRange.from;
         };
         if (revenueRange.to && revenueRange.to != '') {
            params['sales_amount_end'] = revenueRange.to;
         };
         if (dateRange.start && dateRange.end) {
            params['create_time_start'] = dateRange.start;
            params['create_time_end'] = dateRange.end;
         };
         setPayload(params)
         let response = await Axios.post('/api/product-video-hot', params).catch(e => console.error('Axios-Error: ' + e.message));
         dispatch({ type: 'subscription_expired', response: response.data.statusCode });

         if (response && response.status === 200 && response.data.data) {
            let array = await response.data.favorite ? response.data.favorite.map((value, index) => value.video_id) : favourite;

            if (response && response.status === 200) {
               if (page == 0) {
                  setItems(response.data.data);
                  setFavourite(array);
               } else {
                  setItems(items.concat(response.data.data));
                  setFavourite(favourite.concat(array));
               }
               if (response.data.data.length === 0 || response.data.data.length < 20) {
                  setHasMore(false);
               } else {
                  setHasMore(true);
               }
            } else {
               setHasMore(false);
            };
         };
      } catch (error) {
         console.error('Error: ' + error.message);
      };
   };

   const functionNext = async () => {
      let number = page + 1;
      setPage(number);
      productVideos(number)
   };

   const handleApply = (event, picker) => {
      picker.element.val(
         picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD')
      );
      setDateRange({ start: picker.startDate.format('YYYY-MM-DD'), end: picker.endDate.format('YYYY-MM-DD') })
   };
   const handleCancel = (event, picker) => {
      picker.element.val('');
      setDateRange([]);
   };

   const markFavourite = async (data) => {
      await Axios.post('/api/favorite-product-video', data);
      const index = favourite.indexOf(data.id);
      if (index > -1) {
         favourite.splice(index, 1);
      } else {
         favourite.push(data.id);
      }

      setFavourite(favourite.concat([]));
   };

   const handleSort = async (sort, sortType) => {
      if (sort != sorting.sort || (sortType && sortType != sorting.sort_type)) {
         setSorting({ sort, sort_type: sortType });
      };
   };

   return (
      <>
         <Card className='rounded-0'>
            <Card.Body>
               <div className='topfilter'>
                  <p><small>{t('product_research.product_video.filter.long_text_3')}</small></p>
                  <div className="d-flex align-items-center flex-wrap mb-2">
                     <p className="m-0 mr-3"><strong>{t('product_research.product_video.filter.country')}</strong></p>
                     {regionList}
                  </div>
                  <Category singleCategory={false} handleCategory={handleCategory} categoryId1={categoryId1} />
                  <div className="d-flex align-items-center flex-wrap mb-3">
                     <p className="m-0 mr-3"><strong>{t('product_research.product_video.filter.filters')}</strong></p>
                     <div className="dropdown product-rating-menu">
                        <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle"
                           role="button"
                           id="salesRange"
                           data-bs-toggle="dropdown" aria-expanded="false">
                           {t('product_research.product_video.filter.sales')}
                        </a>
                        <div className="dropdown-menu p-3" aria-labelledby="salesRange">
                           <RangeSlider min={0} max={10000000} defaultMin={0} defaultMax={10000000} rangeOption={global.config.rangeOption} rangeOptionName={'sales'} step={10} handleUpdate={(e) => { setSalesRange(e) }} />
                        </div>
                     </div>
                     <div className="dropdown product-rating-menu">
                        <a className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle"
                           role="button"
                           id="revenueRange"
                           data-bs-toggle="dropdown" aria-expanded="false">
                           {t('product_research.product_video.filter.revenue')}
                        </a>
                        <div className="dropdown-menu p-3" aria-labelledby="revenueRange">
                           <RangeSlider min={0} max={10000000} defaultMin={0} defaultMax={10000000} rangeOption={global.config.rangeOption} rangeOptionName={'revenue'} step={10} handleUpdate={(e) => { setRevenueRange(e) }} />
                        </div>
                     </div>
                     <DateRangePicker
                        initialSettings={{
                           autoUpdateInput: false,
                           locale: {
                              cancelLabel: 'Clear',
                           },
                        }} onApply={handleApply} onCancel={handleCancel} /*onCallback={handleCallback}*/
                     >
                        <input type="text" placeholder={t('product_research.product_video.filter.first_seen')} className="form-control form-control-sm input-daterange-timepicker w-auto" />
                     </DateRangePicker>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                     <ButtonGroup aria-label="Basic example" className='mr-2'>
                        {cycleHtml}
                     </ButtonGroup>
                     <div className="dropdown product-rating-menu">
                        <div className="form-group mb-0 mr-3">
                           <select className="form-control form-control-sm" onChange={(e) => { setVersion(e.target.value); }}>
                              {versionList.map((value, index) => (
                                 <option key={index} value={value.version}>{value.label}</option>
                              ))}
                           </select>
                        </div>
                     </div>
                     <p className='text-muted mb-0'><small>{t('product_research.product_video.filter.long_text_2')}</small></p>
                  </div>
                  <div className="d-flex justify-content-end align-items-center mx-2 my-3">
                  <Export payload={payload} data_source="Hot Product Video" type="Product Video"/>
                  </div>
               </div>
            </Card.Body>
         </Card>
         <Card>
            <Card.Body className='p-2'>
               <Table responsive className="w-100">
                  <div id="example_wrapper" className="dataTables_wrapper">
                     <InfiniteScrollWrapper
                        isInfiniteScrollOn={true}
                        lengthData={items.length}
                        functionNext={functionNext}
                        hasMore={hasMore}
                     >
                        <table id="example" className="display w-100 dataTable">
                           <thead>
                              <tr role="row">
                                 <th className='text-right'>#</th>
                                 <th>{t('product_research.product_video.hot_video.video')}</th>
                                 <th>{t('product_research.product_video.hot_video.influencer')}</th>
                                 <th className={`text-right sorting ${19 == sorting.sort ? sortClass : ''}`} onClick={() => { handleSort(19, sorting.sort == 19 ? sortType : 'ASC') }}>{t('product_research.product_video.hot_video.sales')}</th>
                                 <th className={`text-right sorting ${18 == sorting.sort ? sortClass : ''}`} onClick={() => { handleSort(18, sorting.sort == 18 ? sortType : 'ASC') }}>{t('product_research.product_video.hot_video.revenue')}</th>
                                 <th>{t('product_research.product_video.hot_video.products')}</th>
                                 <th className="text-right">{t('product_research.product_video.hot_video.views')}</th>
                                 <th className="text-right">{t('product_research.product_video.hot_video.Likes')}</th>
                                 <th className="text-right">{t('product_research.product_video.hot_video.Comments')}</th>
                                 <th className="text-right">{t('product_research.product_video.hot_video.Shares')}</th>
                                 <th className={`text-right sorting ${26 == sorting.sort ? sortClass : ''}`} onClick={() => { handleSort(26, sorting.sort == 26 ? sortType : 'ASC') }}>{t('product_research.product_video.hot_video.post_date')}</th>
                                 <th className="text-right">{t('product_research.product_video.hot_video.Favourite')}</th>
                              </tr>
                           </thead>
                           <tbody>
                              {items.map((product, index) => (
                                 <tr key={index}>
                                    <td className='text-right'>{index + 1}</td>
                                    <td>
                                       <div className="d-flex">
                                          <div className="prd-img mr-3">
                                             <Link to={'/products/' + product.id + '/video-details'} target="_blank">
                                                {product.origin_cover_privatization && <img src={"https://t-img.picturehaven.net/tikmeta/" + product.origin_cover_privatization + "?imageMogr2/auto-orient/thumbnail/120x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />}
                                             </Link>
                                             <Button
                                                variant="primary"
                                                className="p-0 video-popup-btn"
                                                onClick={() => { setTikTokEmbedID(product.id); setContentModal(!contentModal); }}
                                             >
                                                <i className="fa fa-play-circle" aria-hidden="true"></i>
                                             </Button>
                                          </div>
                                          <div className="prd-title">
                                             <p>
                                                <OverlayTrigger overlay={<Tooltip>{product.desc}</Tooltip>}>
                                                   <Link to={'/products/' + product?.id + '/video-details'} target="_blank">
                                                      <span>{product.desc.length > 40 ? product.desc.substring(0, 40) + '...' : product.desc}</span>
                                                   </Link>
                                                </OverlayTrigger>
                                             </p>
                                          </div>
                                       </div>
                                    </td>
                                    <td>
                                       <div className="d-flex">
                                          <div className="prd-img mr-3">
                                             <Link to={'/influencers/details/' + product.author_id + "/breakout"} target="_blank">
                                                {product.avatar_larger_privatization && <img src={"https://t-img.picturehaven.net/tikmeta/" + product.avatar_larger_privatization + "?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />}
                                             </Link>
                                          </div>
                                          <div className="prd-title">
                                             <p className="mb-0">
                                                <Link to={'/influencers/details/' + product.author_id + "/breakout"} target="_blank">
                                                   {product.author_nickname}
                                                </Link>
                                             </p>
                                             <p className="mb-0 text-muted"><small>{product.sys_country?.country}</small></p>
                                             <p className="mb-0 text-muted"><small>Followers: <Conversion value={product.author_follower_count || 0} /></small></p>
                                          </div>
                                       </div>
                                    </td>
                                    <td className="text-right">
                                       {product.total_estimated_sales_volume_str}
                                    </td>
                                    <td className="text-right">
                                       {product.total_estimated_sales_amount_usd}<br />
                                       <span className="text-muted">({product.total_estimated_sales_amount_str})</span>
                                    </td>
                                    <td>
                                       <Slider className='table-sl-slider' {...settings}>
                                          {product.product_list.map((carousel, i) => (
                                             <div className='product-slider'>
                                                <div className='d-flex align-items-start'>
                                                   <OverlayTrigger
                                                      key="right"
                                                      trigger={["hover", "focus"]}
                                                      placement="right"
                                                      rootClose
                                                      overlay={
                                                         <Popover>
                                                            <Popover.Body>
                                                               {carousel.images_privatization[0] && <img src={"https://t-img.picturehaven.net/product/" + carousel.images_privatization[0] + "?imageMogr2/auto-orient/thumbnail/500x/strip/format/WEBP/quality/75!/ignore-error/1"} className="d-block" width={500} alt={`slide`} />}
                                                            </Popover.Body>
                                                         </Popover>
                                                      }
                                                   >
                                                      <Link to={'/products/' + carousel.product_id + '/details'} target="_blank">
                                                         {carousel.images_privatization[0] && <img src={"https://t-img.picturehaven.net/product/" + carousel.images_privatization[0] + "?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={70} className="prod-thum-img" />}
                                                      </Link>
                                                   </OverlayTrigger>
                                                   <div className="prd-title">
                                                      <Link to={'/products/' + carousel.product_id + '/details'} target="_blank">
                                                         <div className="carousel-caption">
                                                            <p className='mb-0'>{carousel.title}</p>
                                                            <p className='mb-0'><small>{carousel.min_price_usd}</small></p>
                                                            <p className='mb-0'><small>Revenue: {carousel.estimated_sales_amount_usd}</small></p>
                                                         </div>
                                                      </Link>
                                                   </div>

                                                </div>
                                             </div>
                                          ))}
                                       </Slider>
                                    </td>
                                    <td className="text-right">
                                       <Conversion value={product.play_count || 0} />
                                    </td>
                                    <td className="text-right">
                                       <Conversion value={product.digg_count || 0} />
                                    </td>
                                    <td className='text-right'>
                                       <Conversion value={product.comment_count || 0} />
                                    </td>
                                    <td className="text-right">
                                       <Conversion value={product.share_count || 0} />
                                    </td>
                                    <td className='text-right'>
                                       {product.create_time ? new Date(product.create_time * 1000).toISOString().split('T')[0] : ''}
                                    </td>
                                    <td className="text-right">
                                       <div className="d-flex justify-content-end">
                                          {<Button
                                             variant="outline-dark" size="xs"
                                             className="px-2 py-1"
                                             onClick={() => { markFavourite({ id: product.id, country_code: product.country_code }); }}>
                                             <i className={"fa " + (favourite.indexOf(product.id) > -1 ? "fa-star text-primary" : "fa-star-o")} aria-hidden="true"></i>
                                          </Button>}
                                       </div>
                                    </td>
                                 </tr>
                              ))}
                           </tbody>
                        </table>
                     </InfiniteScrollWrapper>
                  </div>
               </Table>
            </Card.Body>
         </Card>

         {/* <!-- Video Modal --> */}
         <Modal className="fade video-popup" show={contentModal}>
            <Modal.Body className='p-0'>
               <div className='d-flex'>
                  <div className='text-center'>
                     <TikTokEmbed url={`https://www.tiktok.com/embed/${tikTokEmbedID}`} width={325} />
                  </div>
                  <div className='px-3'>
                     <div className='d-flex flex-column'>
                        <Button
                           variant="dark"
                           className="mb-3"
                           onClick={() => { setTikTokEmbedID(''); setContentModal(!contentModal); }}
                        >
                           <i className="fa fa-times" aria-hidden="true"></i>
                        </Button>
                        {/* <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-line-chart" aria-hidden="true"></i>
                        </Button>
                        <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-download" aria-hidden="true"></i>
                        </Button>
                        <Button
                           variant="primary"
                           className="mb-3"
                        >
                           <i className="fa fa-file-code-o" aria-hidden="true"></i>
                        </Button> */}
                     </div>
                  </div>
               </div>
            </Modal.Body>
         </Modal>
      </>
   );
};

export default HotVideo;
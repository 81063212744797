import { React } from 'react';
import { useTranslation } from 'react-i18next';

const Category = ({ category, showCategory, handleCategory, categoryId1 }) => {
   const { t } = useTranslation();

   const categoryIds = showCategory.map(function(value) {
     return parseInt(value.id);
   });

   const selectCategory = async (categoryId, categoryName) => {
      let categoryIds = [], categoryNames = [];
      if (categoryId != '') {
         categoryIds = categoryId.toString().split(",");
      }
      if (typeof categoryName !== 'undefined' && categoryName != '') {
         categoryNames = categoryName.toString().split(",");
      }
      handleCategory(categoryIds, categoryNames);
   };

   const RecursiveComponent = ({ parentCategory, className, previousCategoryId, previousCategoryName, parent }) => {
      previousCategoryId = previousCategoryId ? previousCategoryId : parentCategory.id
      previousCategoryName = previousCategoryName ? previousCategoryName : parentCategory.category

      return (
         <ul key={parentCategory.id} className={className} aria-labelledby="dropdownMenuLink">
            {
               parent && <li key={"all-" + parentCategory.id} style={{ cursor: 'pointer' }}> <button className="dropdown-item" onClick={() => { selectCategory(previousCategoryId, previousCategoryName); }}>All</button> </li>
            }
            {
               parentCategory.child.map((category, index) => (
                  <li key={category.id} style={{ cursor: 'pointer' }}>
                     <a className="dropdown-item" onClick={() => { selectCategory(previousCategoryId + "," + category.id, previousCategoryName + "," + category.category); }}>
                        {category.category}
                     </a>
                  </li>
               ))
            }
         </ul>
      );
   };

   return (
      <>
         <div className="d-flex align-items-center flex-wrap mb-2">
            <p className="m-0 mr-3"><strong>{t('common.category')}</strong></p>
            <a
               className={"btn " + (categoryId1 == '' ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 mb-2"}
               onClick={() => { selectCategory('', ''); }}>
               All
            </a>
            {
               category.map((category, index) => ( (categoryIds.indexOf(category.id) !== -1) ? 
                  (
                     (<div key={index} className="dropdown">
                        <a
                           className={"btn " + (categoryId1 == category.id ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 dropdown-toggle mb-2"}
                           onClick={() => { selectCategory(category.id, category.category); }}>
                           {category.category}
                           <span className='cl-fl' onClick={() => { alert('alert'); }}></span>
                        </a>
                        {category.child.length > 0 && <RecursiveComponent parentCategory={category} parent={true} className={'dropdown-menu'} />}
                     </div>)
                  ) : ''
               ))
            }
         </div>
      </>
   );
};

export default Category;
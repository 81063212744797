import Axios from "../../plugins/axios";
import React, { useState, useRef } from 'react';
import { Button, Modal, Card } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCard from "../StripeCard/Stripecard";
import { useTranslation } from 'react-i18next';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Billing = ({ user, func }) => {
    const { t } = useTranslation();

    const stripeRef = useRef(null);
    const addressRef = useRef(null);
    const [modalShow, setModalShow] = useState(false);
    const [address, setAddress] = useState(null);
    const [stripeMessage, setStripeMessage] = useState('');

    const onSelectAddress = async () => {
        const autocomplete = new window.google.maps.places.Autocomplete(addressRef.current);

        window.google.maps.event.addListener(autocomplete, 'place_changed', async function () {
            const place = await autocomplete.getPlace();
            if (place.address_components) {
                let addressObj = {
                    address: place.formatted_address,
                    postal: place.address_components[place.address_components.length - 1]?.long_name,
                    country: place.address_components[place.address_components.length - 2]?.long_name,
                    state: place.address_components[place.address_components.length - 3]?.long_name,
                    city: place.address_components[place.address_components.length - 4]?.long_name,
                };
                setAddress(addressObj)
            };
        });
    };

    const submitHandler = async () => {
        try {
            let updateObj = {};
            if (address) updateObj.address_details = address;

            let stripeResponse = await stripeRef.current.stripePaymentMethod(user.full_name, user.email);

            if (stripeResponse.statusCode === 200 && stripeResponse.data && stripeResponse.data.id) {
                updateObj.earlierPaymentMethodId = user.payment.id;
                updateObj.paymentMethodId = stripeResponse.data.id;
            } else {
                setStripeMessage(stripeResponse.message);
            };

            if (Object.keys(updateObj).length != 0) {
                const response = await Axios.post('/api/billing', updateObj).catch((e) => {
                    console.error('Axios-Catch: onBlurEmail ' + e.message);
                });

                if (response && response.status === 200) {
                    setModalShow(!modalShow)
                    func();
                };
            };
        } catch (error) {
            console.error('Error Catch' + error.message);
        };
    };

    return (
        <>
            <Card>
                <Card.Body>
                    <h4>{t('user_profile.billing_information')}</h4>
                    <p>{t('user_profile.you_can_edit_change_your_billing_information_here')}</p>

                    <h4>{t('user_profile.payment_method')}</h4>
                    <div className="form-group">
                        <div className="d-flex align-items-center">
                            <input
                                type="text"
                                className="form-control form-control-sm w-auto mr-2"
                                value={`**** **** **** ${user.pm_last_four != null ? user.pm_last_four : '****'}`}
                                disabled
                            />
                            <span>{user.pm_type}</span>
                        </div>
                    </div>

                    <div className="form-group">
                        <h4>{t('user_profile.expiry_date')}</h4><span>{user?.payment?.card?.exp_month || '--'}/{user?.payment?.card?.exp_year || '--'}</span>
                    </div>

                    <div className="form-group">
                        <h4>{t('user_profile.billing_address')}</h4>
                        <p>{user.address_details ? `${user.address_details.address ? user.address_details.address : '-'}` : '-'}</p>
                    </div>

                    <button type="button" className="btn btn-primary btn-sm" onClick={() => setModalShow(!modalShow)} >{t('user_profile.update_billing_information')}</button>
                </Card.Body>
            </Card>

            <Modal
                className="fade bd-example-modal-lg"
                show={modalShow}
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>{t('user_profile.update_billing_information')}</Modal.Title>
                    <Button
                        variant="danger"
                        className="close"
                        size="sm"
                        onClick={() => setModalShow(!modalShow)}
                    >
                        X
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <input
                            ref={addressRef}
                            type="text"
                            className={`form-control`}
                            placeholder="Full Address"
                            onSelect={() => { onSelectAddress() }}
                        />
                    </div>
                    <div className="form-group" onClick={() => setStripeMessage('')}>
                        <Elements stripe={stripePromise}>
                            <StripeCard ref={stripeRef} />
                        </Elements>
                        {stripeMessage && <div className="text-danger">{stripeMessage}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        size="sm"
                        onClick={() => setModalShow(!modalShow)}
                    >
                        {t('user_profile.cancel')}
                    </Button>
                    <Button
                        variant="primary"
                        type="button"
                        size="sm"
                        className="btn btn-primary"
                        onClick={() => submitHandler()}
                    >
                        {t('user_profile.update')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Billing;